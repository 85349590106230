<template>
	<div
		tabindex="0"
		class="chip"
		:class="[
			{
				active,
			},
			color,
			error && 'error',
			size && `size-${size}`,
			icon && 'icon-left',
			closeable && 'icon-right',
		]"
		v-if="!isClosed"
		@click="handleClick"
	>
		<TnIcon
			v-if="icon"
			:name="icon"
			:size="size === 'l' ? 'm' : 's'"
		/>
		<span class="text"> {{ text }} </span>
		<TnIcon
			v-if="closeable"
			name="remove"
			:size="size === 'l' ? 'm' : 's'"
		/>
	</div>
</template>

<script>
import { defineComponent } from "vue";

import sizes from "./definitions/sizes";
import colors from "./definitions/colors";

/**
 * TnChip collects small pieces of information and allow users to make selections, filter content or trigger actions. Should appear dynamically as a group of multiple interactive elements.
 *
 * Typical use cases:
 *
 * - Type in an email address and get visual feedback that the email they have entered is valid
 * - See and remove selections they have made in a filter
 * - See and click relevant content on the top or bottom of an article
 *
 * @displayName TnChip
 */
export default defineComponent({
	name: "TnChip",

	props: {
		/**
		 * Text/Label of the chip
		 */
		text: {
			type: String,
			default: "Label",
		},
		/**
		 * Size of the chip
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Sets the state type of the chip
		 * @values grey, white, dark
		 */
		color: {
			type: String,
			default: "grey",
			validator: function (value) {
				return colors.includes(value.toLowerCase());
			},
		},
		/**
		 * Error/invalid state
		 */
		error: {
			type: Boolean,
			default: false,
		},
		/**
		 * Close-icon to display on the right side of the chip
		 */
		closeable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the left side of the chip
		 */
		icon: {
			type: String,
			default: "",
		},
		active: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Event emitted when the chip is clicked
		 */
		"click",
	],

	data() {
		return {
			keyboardFocus: false,
			isClosed: false,
		};
	},

	computed: {
		errorType() {
			if (this.error && this.color === "dark") {
				return "dark-error";
			} else if (this.error) {
				return "default-error";
			} else {
				return this.color;
			}
		},
	},

	methods: {
		handleClick() {
			this.$emit("click");
			this.isClosed = this.closeable;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/css/typography.css";
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/all" as mixins;
@use "@/assets/typography/scss/mixins" as typograhpy;

.chip {
	--background-color: #{variables.$color-neutrals-50-tint};
	--foreground-color: #{variables.$color-neutrals-900-shade};
	--hover-background-color: #{variables.$color-neutrals-100-tint};
	--active-background-color: #{variables.$color-neutrals-200-tint};
	--active-foreground-color: #{variables.$color-neutrals-black};
	--focus-outline-color: #{variables.$color-cta-focus};
	--padding-horizontal: 11px;
	--padding-left: 11px;
	--padding-right: 11px;
	--padding-vertical: 6px;
	--gap: 6px;

	box-sizing: content-box; // already default
	cursor: pointer;
	display: inline-flex;
	gap: var(--gap);
	align-items: center;
	justify-content: center;
	background-color: var(--background-color);
	color: var(--foreground-color);
	user-select: none;
	border-radius: 4px;
	padding: var(--padding-vertical) var(--padding-right) var(--padding-vertical) var(--padding-left);

	&:hover {
		background-color: var(--hover-background-color);
	}

	&:active,
	&.active {
		background-color: var(--active-background-color);
		color: var(--active-foreground-color);
	}

	&:focus-visible {
		outline: 2px solid var(--focus-outline-color);
		outline-offset: 3px;
	}

	&.error,
	&.white.error {
		--background-color: #{variables.$color-critical-200-tint};
		--foreground-color: #{variables.$color-critical-800-shade};
		--hover-background-color: #{variables.$color-critical-200-tint};
		--active-background-color: #{variables.$color-critical-200-tint};

		&:hover {
			box-shadow: 0 0 0 1px variables.$color-critical-500-core;
		}

		&.active,
		&:active {
			box-shadow: 0 0 0 1px variables.$color-critical-500-core;
		}
	}

	&.white {
		--background-color: #{variables.$color-neutrals-white};
		--hover-background-color: #{variables.$color-neutrals-white};
		--active-background-color: #{variables.$color-neutrals-25-tint};
		--active-foreground-color: #{variables.$color-neutrals-black};

		@include mixins.shadow(s);

		&:hover {
			@include mixins.shadow-hover(s);
		}

		&.active,
		&:active {
			@include mixins.shadow(s);
		}
	}

	&.dark {
		--background-color: #{variables.$color-neutrals-700-shade};
		--foreground-color: #{variables.$color-neutrals-25-tint};
		--hover-background-color: #{variables.$color-neutrals-800-shade};
		--active-background-color: #{variables.$color-neutrals-900-shade};
		--active-foreground-color: #{variables.$color-neutrals-white};
		--focus-outline-color: #{variables.$color-cta-dark-focus};

		&.error {
			--background-color: #{variables.$color-critical-800-shade};
			--hover-background-color: #{variables.$color-critical-800-shade};
			--active-background-color: #{variables.$color-critical-800-shade};
			--foreground-color: #{variables.$color-critical-200-tint};
		}
	}

	&.size {
		&-s {
			--gap: 4px;
			--padding-left: 11px;
			--padding-right: 11px;
			--padding-vertical: 4px;

			@include typograhpy.font-text-2xs;

			&.icon-left {
				--padding-left: 8px;
			}

			&.icon-right {
				--padding-right: 6px;
			}
		}

		&-m {
			--padding-left: 13px;
			--padding-right: 13px;
			--padding-vertical: 6px;

			@include typograhpy.font-text-xs;

			&.icon-left {
				--padding-left: 12px;
			}

			&.icon-right {
				--padding-right: 8px;
			}
		}

		&-l {
			--gap: 8px;
			--padding-left: 21px;
			--padding-right: 21px;
			--padding-vertical: 7px;
			--padding-left-icon: 0 0;

			@include typograhpy.font-text-m;

			&.icon-left {
				--padding-left: 12px;
			}

			&.icon-right {
				--padding-right: 12px;
			}
		}
	}
}
</style>
